import nl from '@/locales/nl.json';
import en from '@/locales/en.json';

export default defineI18nConfig(() => ({
  legacy: false,
  locale: localStorage.getItem('locale') || 'nl',
  fallbackLocale: 'nl',
  messages: {
    en,
    nl,
  },
}));
